import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { ActionLink } from '@rsa-digital/evo-shared-components/components/Link';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const QuoteNumberQuestionField = styled(QuestionField)`
  margin-bottom: 0;
`;

export const ActionLinkWithBottomMargin = styled(ActionLink)<{
  children?: React.ReactNode;
}>`
  margin-bottom: ${spacing(4)};
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;

  ${mediaQuery.tabletPortrait`
    width: 50%;
  `};
`;
