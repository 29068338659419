import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FormFooter from 'components/FormFooter';
import QuestionField from 'components/QuestionField';
import {
  PageTitle,
  trackFieldError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import {
  INPUT_REGEX_ALPHANUMERIC,
  INPUT_REGEX_ALPHANUMERIC_WITH_SINGLE_SPACE,
} from 'helpers/inputRegexes';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { useRetrieveQuoteDetails } from 'state/formData/retrieveQuote';
import { CsQuestionWithPlaceholder } from 'types/contentStack';
import {
  ActionLinkWithBottomMargin,
  QuoteNumberQuestionField,
  StyledTextInput,
} from './styles';
import useRetrieveLatestQuoteRules from './validation';

type CsRetrieveQuoteFormContent = {
  csPetRetrieveYourQuote: {
    submit_cta_text: string;
    quote_number_question: CsQuestionWithPlaceholder;
    postcode_question: CsQuestionWithPlaceholder;
    error_messages: {
      missing: string;
    };
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourQuote {
      submit_cta_text
      quote_number_question {
        main_details {
          explanatory_text
          placeholder
          alert_text
          question_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
        }
        error_messages {
          missing
        }
      }
      postcode_question {
        main_details {
          explanatory_text
          placeholder
          alert_text
          question_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
        }
      }
    }
  }
`;

const RetrieveQuoteReferenceForm: React.FC<{
  moveNext: () => void;
  forgottenReferenceLink?: {
    text: string;
    screenreaderText?: string;
    onClick: React.MouseEventHandler;
  };
}> = ({ moveNext, forgottenReferenceLink }) => {
  const { submit_cta_text, quote_number_question, postcode_question } = useStaticQuery<
    CsRetrieveQuoteFormContent
  >(query).csPetRetrieveYourQuote;
  const [retrieveQuoteDetails, updateRetrieveQuoteDetails] = useRetrieveQuoteDetails();
  const { processQuestionWithOptionalPlaceholder } = useQuestionProcessor(
    PageTitle.RetrieveQuoteReference
  );

  const quoteNumberQuestion = processQuestionWithOptionalPlaceholder(
    quote_number_question
  );
  const postcodeQuestion = processQuestionWithOptionalPlaceholder(postcode_question);

  const formErrorRules = useRetrieveLatestQuoteRules();

  const { getError, showValidation, validateOnSubmit } = useValidation(
    retrieveQuoteDetails,
    formErrorRules,
    trackFieldError
  );

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <QuoteNumberQuestionField
        question={quoteNumberQuestion}
        errorText={getError('quoteNumber')}>
        <TextInput
          id="quoteNumber"
          placeholder={quoteNumberQuestion.placeholder}
          value={retrieveQuoteDetails.quoteNumber}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC)) {
              updateRetrieveQuoteDetails({
                quoteNumber: e.target.value.toUpperCase(),
              });
            }
          }}
          onBlur={() => {
            showValidation('quoteNumber');
          }}
          onFocus={trackFormTextFieldFocus('Quote number')}
        />
      </QuoteNumberQuestionField>
      {forgottenReferenceLink && (
        <ActionLinkWithBottomMargin
          onClick={forgottenReferenceLink.onClick}
          aria-label={forgottenReferenceLink.screenreaderText}>
          {forgottenReferenceLink.text}
        </ActionLinkWithBottomMargin>
      )}
      <QuestionField question={postcodeQuestion} errorText={getError('customerPostcode')}>
        <StyledTextInput
          id="customerPostcode"
          placeholder={postcodeQuestion.placeholder}
          maxLength={8}
          value={retrieveQuoteDetails.customerPostcode}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC_WITH_SINGLE_SPACE)) {
              updateRetrieveQuoteDetails({
                customerPostcode: e.target.value.toUpperCase().trim(),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerPostcode');
          }}
          onFocus={trackFormTextFieldFocus('Customer postcode')}
        />
      </QuestionField>
      <FormFooter
        contentColumns={{ desktop: 6, tabletLandscape: 6 }}
        moveNextButton={{
          text: submit_cta_text,
          onClick: () =>
            trackTextButtonClick(PageTitle.RetrieveQuoteReference, submit_cta_text),
        }}
        pageTitle={PageTitle.RetrieveQuoteReference}
      />
    </form>
  );
};

export default RetrieveQuoteReferenceForm;
