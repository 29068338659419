import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import useRetrieveQuote from 'apiHelpers/quote/useRetrieveQuote';
import { AxiosError } from 'axios';
import { isQuoteNotFoundError } from 'businessLogic/errors/isQuoteNotFoundError';
import { graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import RichTextWithModal from 'components/RichTextWithModal';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import RetrieveQuoteReferenceForm from 'forms/RetrieveQuoteReferenceForm';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackAPIError, trackTextButtonClick } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useLoadingState from 'helpers/useLoadingState';
import { DivWithTopMargin, ErrorPanelWithMargin } from './styles';

type RetrieveQuoteReferenceProps = {
  data: {
    csPetRetrieveYourQuote: {
      meta_title: string;
      hero: {
        heading: string;
        subheading: string;
      };
      error_messages: {
        not_found: string;
      };
      forgotten_reference_link: {
        text: string;
        screenreader_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourQuote {
      meta_title
      hero {
        heading
        subheading
      }
      error_messages {
        not_found
      }
      forgotten_reference_link {
        text
        screenreader_text
      }
    }
  }
`;

const RetrieveQuoteReference: React.FC<RetrieveQuoteReferenceProps> = ({
  data: {
    csPetRetrieveYourQuote: {
      meta_title,
      hero: { heading, subheading },
      error_messages,
      forgotten_reference_link: { text, screenreader_text },
    },
  },
}) => {
  usePageTracking(meta_title);
  const { retrieveQuoteByInputReferenceAndNavigate } = useRetrieveQuote();
  const { isLoading, withLoadingState } = useLoadingState();
  const [errorPanelText, setErrorPanelText] = useState<string>('');
  const errorHandler = useDefaultErrorHandling();

  const link = {
    text,
    screenreaderText: screenreader_text,
    onClick: () => {
      trackTextButtonClick(
        PageTitle.RetrieveQuoteReference,
        'Forgotten your quote reference?'
      );
      navigate(quoteAndBuyRoutes.retrieveQuote);
    },
  };

  return (
    <Layout
      pageTitle={PageTitle.RetrieveQuoteReference}
      metaTitle={meta_title}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      {isLoading && <LoadingOverlay loadingMessage="Retrieving quote, please wait" />}
      <HeroBanner heading={heading} subheading={subheading} />
      <DivWithTopMargin>
        {errorPanelText && (
          <FieldGrid alignLeft>
            <ErrorPanelWithMargin id="retrieve-quote-error-panel">
              <RichTextWithModal
                pageTitle={PageTitle.RetrieveQuoteReference}
                aria-live="assertive"
                html={errorPanelText}
              />
            </ErrorPanelWithMargin>
          </FieldGrid>
        )}
        <RetrieveQuoteReferenceForm
          moveNext={async () => {
            try {
              setErrorPanelText('');
              await withLoadingState(retrieveQuoteByInputReferenceAndNavigate);
            } catch (err) {
              const error: AxiosError = err as AxiosError;
              if (isAxiosError(error) && isQuoteNotFoundError(error)) {
                trackAPIError(error);
                setErrorPanelText(error_messages.not_found);
                scrollToElement('retrieve-quote-error-panel', 20);
              } else {
                errorHandler(error);
              }
            }
          }}
          forgottenReferenceLink={link}
        />
      </DivWithTopMargin>
    </Layout>
  );
};

export default RetrieveQuoteReference;
